@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body,
#root {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  position: relative;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
  letter-spacing: .60px;
  margin: 0;
  overflow: auto;
  overflow: initial;
}

:root {
  --bg-primary: #091422;
  --bg-head-primary: #122231;
  --bg-portal-primary: #1b2633;
  --bg-body-primary: #18181b;
  --bg-secondary: rgb(15, 20, 24);
  --text-primary: #D5D8DC;
  --text-secondary: rgb(15, 20, 24);
  --hover-bg-primary: #2C3E50;
  --hover-bg-secondary: rgb(15, 20, 24);
  --modal-bg: #c7c7c7;
  --bg-btn-reg-primary: #1D8348;
  --bg-btn-reg-primary-hover: #239B56;
  --bg-btn-reg-primary-active: #2ECC71;
  --btn-reg-text-color-primary: #0A2C19;
  --bg-btn-act-primary: #F1C40F;
  --bg-btn-act-primary-hover: #F4D03F;
  --bg-btn-act-primary-active: #F7DC6F;
  --btn-act-text-color-primary: #643D1B;
  --scroll-bar-color: #22303d;
  --scroll-bar-bg: transparent;
  --scroll-ancho: 1px;
  --swal2-info-text-color: #9e9e9e;
  --swal2-title-text-color: #c9c9c9;
  --swal2-bg: #040b11;
  --font-family: 'Bellota Text', cursive;

}

.alert-danger {
  @apply bg-rose-100 text-rose-700 p-3 mb-2 border-l-4 border-rose-600 text-sm font-medium;
}

.alert-success {
  @apply bg-brand-100 text-brand-900 p-3 mb-2 text-sm font-medium border-l-4 border-brand-600;
}

.pagination-link {
  @apply border border-brand-800 text-brand-500 text-sm font-medium block py-2 px-4 bg-brand-900 border-r-0;
}

.pagination-link-light {
  @apply border border-brand-400 text-brand-700 text-sm font-medium block py-2 px-4 bg-white border-r-0;
}

.pagination-li:last-child {
  @apply border-r border-r-brand-400
}

.size {
  @apply border border-brand-400 ml-2 px-3 py-1.5 mt-2 uppercase cursor-pointer rounded text-brand-400 text-sm;
}

.right-heading {
  @apply capitalize my-2 text-brand-400 text-base;
}

.ql-toolbar.ql-snow {
  @apply border-none bg-brand-900 !important;
}

.MuiTooltip-tooltip {
  @apply !rounded-lg !bg-lightPrimary/80 dark:!bg-darkPrimary/80 py-3 px-4 !text-sm !shadow-xl !text-white;
}

.MuiTooltip-arrow::before {
  @apply !bg-lightPrimary/80 dark:!bg-darkPrimary/80;
}

.ql-container.ql-snow {
  @apply border-none bg-brand-700 !important;
}

.my-container {
  @apply max-w-screen-xl w-full mx-auto;
}

.flex-y {
  @apply flex items-center;
}

.heading {
  @apply text-lg capitalize font-medium;
}

.error {
  @apply block mt-1.5 text-rose-600 font-medium text-sm;
}

.account-list {
  @apply flex items-center px-3 py-3.5 rounded-md;
}

.account-list-title {
  @apply block ml-3 font-medium text-sm text-brand-700 capitalize;
}

a.active {
  @apply bg-brand-50;
}

.description h1 {
  @apply text-4xl capitalize;
}

.description h2 {
  @apply text-3xl capitalize;
}

.description h3 {
  @apply text-2xl capitalize;
}

.description h4 {
  @apply text-xl capitalize;
}

.swiper-button-next,
.swiper-button-prev {
  opacity: 0;
  /* Oculta los botones inicialmente */
  transition: opacity 0.3s;
  /* Transición suave para la opacidad */
}

.swiper-container:hover .swiper-button-next,
.swiper-container:hover .swiper-button-prev {
  opacity: 1;
  /* Muestra los botones al pasar el ratón */
}

.MuiFormHelperText-root {
  color: #838383ee !important;
}

/* style.css */
.MuiButton-containedPrimary {
  @apply !bg-lightPrimary dark:!bg-darkPrimary;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
}

.MuiButton-containedPrimary:hover {
  @apply !bg-lightPrimary/80 dark:!bg-darkPrimary/80;
}

.MuiButton-containedSuccess {
  @apply !bg-lightSuccess dark:!bg-darkSuccess;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
}

.MuiButton-containedSuccess:hover {
  @apply !bg-lightSuccess/80 dark:!bg-darkSuccess/80;
}

.MuiButton-containedError {
  @apply !bg-[#e02424];
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
}

.MuiButton-containedError:hover {
  @apply !bg-[#c52020];
}

.MuiButton-containedPrimary.Mui-disabled {
  @apply !bg-opacity-20 dark:!bg-opacity-20 opacity-50 !text-white dark:!text-white !cursor-not-allowed;
}
.MuiButton-containedSuccess.Mui-disabled{
  @apply !bg-opacity-20 dark:!bg-opacity-20 opacity-50 !text-white dark:!text-white !cursor-not-allowed;
}
.MuiButton-containedError.Mui-disabled{
  @apply !bg-opacity-20 dark:!bg-opacity-20 opacity-50 !text-white dark:!text-white !cursor-not-allowed;
}

.bg-blue-custom {
  background-color: #0545C6 !important;
}

.bg-gradient-blue-custom {
  background: linear-gradient(0deg, #00008b 0%, #0000ca);
}

.bg-gradient-red-custom {
  background: rgb(159, 6, 6);
  background: linear-gradient(0deg, #dc0000 0%, rgb(230, 3, 3) 100%);
}

input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

.item-container {
  background-color: var(--bg-body-primary);
  width: calc(33.3% - 11px);
  height: auto;
  border-bottom: 6px solid var(--bg-body-primary);
  border-top: 6px solid var(--bg-body-primary);
  border-radius: 6px;
  animation-name: mostrar-30;
  animation-duration: 1s;
  position: relative;
  margin-left: 10px;
  margin-top: 20px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  @apply border border-none hover:outline hover:outline-none hover:outline-1;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  @apply text-brand-900 dark:text-brand-100 rounded-lg border-2 border-transparent;
}

.MuiOutlinedInput-root .MuiOutlinedInput-text {
  @apply text-brand-900 dark:text-brand-100;
}

.custom-style-input{
  @apply border-2 !border-lightPrimary/20 dark:!border-darkPrimary/20 bg-white dark:bg-brand-900 p-[15px] text-brand-950 dark:text-brand-50 text-base rounded-lg focus:ring-lightPrimary block w-full placeholder-brand-300 dark:placeholder-brand-600 dark:focus:ring-darkPrimary focus:outline-none focus:border-2 focus:border-lightPrimary dark:focus:border-darkPrimary focus:ring-2;
}

.custom-style-input-nav{
  @apply bg-white p-[10px] text-brand-950 text-sm rounded-lg block w-full placeholder-brand-300 dark:placeholder-brand-600 outline-none;
}

input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="number"]:disabled,
select:disabled,
textarea:disabled {
  @apply bg-brand-300 bg-opacity-30 dark:bg-brand-600 dark:bg-opacity-30;
  cursor: not-allowed; /* Optional: Change cursor to indicate disabled state */
}

.MuiOutlinedInput-root{
  @apply !max-h-[57px] !border-2 !border-lightPrimary/20 dark:!border-darkPrimary/20 hover:!ring-0 !rounded-lg
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  @apply flex !max-h-[20px] !min-h-[20px] items-center bg-white dark:bg-brand-900 text-brand-950 dark:text-brand-50 text-base block w-full placeholder-brand-300 dark:placeholder-brand-600 !rounded-lg focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!outline-0 focus:!border-none focus:!border-lightPrimary dark:focus:!border-darkPrimary focus:!ring-0;
}

.MuiSvgIcon-root.MuiPaginationItem-icon {
  @apply text-brand-900 dark:text-brand-100 hover:text-brand-300 dark:hover:text-brand-300 z-20 !bg-white dark:!bg-brand-900 rounded-full hover:bg-brand-500;
}

.MuiSvgIcon-root.MuiSelect-icon {
  @apply text-brand-900 dark:text-brand-100 z-20 bg-brand-300 dark:bg-brand-700 rounded-full;
}

.MuiPaginationItem-root.MuiPaginationItem-page {
  @apply bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-100 hover:!bg-lightPrimary/80 dark:hover:!bg-darkPrimary/80 hover:text-white;
}

.MuiPaginationItem-ellipsis {
  @apply text-brand-500 dark:text-brand-100;
}

.MuiButtonBase-root.Mui-selected.MuiPaginationItem-page {
  @apply bg-lightPrimary dark:bg-darkPrimary text-white;
}

.container-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.label-perfiles {
  position: absolute;
  margin-top: -25px;
  color: #d6d3d1;
}

.swal-modal {
  background: var(--alert-bg-color) !important;
}

.swal2-html-container {
  color: var(--swal2-info-text-color) !important;
}

.swal2-title {
  color: var(--swal2-title-text-color) !important;
}

.swal2-popup {
  background: var(--swal2-bg) !important;
}

.animation {
  animation-name: mostrar-30;
  animation-duration: .5s;
}

.scale {
  animation-name: scale;
  animation-duration: 1s;
}

@keyframes mostrar-30 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scale {
  from {
    transform: scale(0%)
  }

  to {
    transform: scale(100%);
  }
}

.MuiSwitch-root.MuiButtonBase-root.MuiSwitch-track{
  @apply h-16 bg-red-600;
}
input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

::-webkit-scrollbar {
  width: var(--scroll-ancho);
  background: transparent;
}

::-webkit-scrollbar-thumb {
  width: var(--scroll-ancho);
  background: transparent;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.2s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  @apply bg-brand-900;
  @apply text-brand-300;
  font-size: 13px;
  position: absolute;
  padding: 5px 8px;
  display: flex;
  right: 94%;
  top: 50%;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  border-radius: 10px;

}

.float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 10px;
  right: 10px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.float:hover {
  background-color: #178b42;
}

.my-float {
  margin-top: 16px;
}

[data-title] {
  position: relative;
}

.MuiMenuItem-root{
  @apply text-brand-950 dark:text-brand-50 bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800 pt-2 pb-2 !important;
}

.MuiList-root{
  @apply bg-white dark:bg-brand-900 !important;
}
.card {
  position: relative;
  background: transparent;
  border-top: 1px solid rgba(255, 49, 49, 0.5);
  border-right: 1px solid rgba(255, 49, 49, 0.5);
  border-bottom: 1px solid rgba(255, 49, 49, 0.5);
  border-left: 1px solid rgba(255, 49, 49, 0.5);
  padding: 1em;
}

.spanes {
  position: absolute;
  border-radius: 100vmax;
}

.top {
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: linear-gradient(90deg,
      transparent 50%,
      rgba(255, 49, 49, 0.5),
      rgb(255, 49, 49));
}

.bottom {
  right: 0;
  bottom: 0;
  height: 5px;
  background: linear-gradient(90deg,
      rgba(255, 49, 49, 0.5),
      rgb(255, 49, 49),
      transparent 50%);
}

.editor-wrapper {
  border: 1px solid #171717;
  background: #171717;
}

.message-toolbar {
  border-bottom: 1px solid #171717;
  margin-bottom: 0px;
  padding: 6px 5px;
}

.rdw-editor-toolbar,
.message-toolbar {
  border-bottom: 1px solid #797979 !important;
  border-top: 1px solid #171717 !important;
  border-right: 1px solid #171717 !important;
  border-left: 1px solid #171717 !important;
  @apply border-2 border-lightPrimary/20 dark:border-darkPrimary/20 bg-white dark:bg-brand-900 text-brand-950 dark:text-brand-50 text-base !important;
}

.rdw-editor-main {
  @apply border-2 border-lightPrimary/20 dark:border-darkPrimary/20 bg-white dark:bg-brand-900 text-brand-950 dark:text-brand-50 text-base !important;
  padding: 10px !important;
}

.rdw-image-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  border: 1px solid #3d3d3d !important;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  @apply bg-brand-300 dark:bg-brand-800 !important;
  box-shadow: none !important;
}

.kr-payment-button {
  color: white !important;
  font-family: "Montserrat", sans-serif !important;
  border-radius: 10px !important;
  font-weight: bold !important;
}


.rdw-dropdown-selectedtext {
  color: #1E293B;
}

.rdw-dropdown-optionwrapper {
  @apply bg-brand-300 dark:bg-brand-800 !important;
}

.message-editor {
  height: 250px !important;
  font-size: 16px;
  padding: 0px 10px;
}

.rdw-option-wrapper {
  min-width: 35px;
  height: 60px;
}

.html-output {
  border: 1px solid #171717;
  padding: 20px;
  background-color: #171717;
}

.right {
  top: 0;
  right: 0;
  width: 5px;
  height: 0;
  background: linear-gradient(180deg,
      transparent 30%,
      rgba(255, 49, 49, 0.5),
      rgb(255, 49, 49));
}

.text {
  display: inline;
  width: 100%;
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

.left {
  left: 0;
  bottom: 0;
  width: 5px;
  height: 0;
  background: linear-gradient(180deg,
      rgba(255, 49, 49, 0.5),
      rgb(255, 49, 49),
      transparent 70%);
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #4CAF50;
  color: white;
  padding: 12px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
  z-index: 1;
}

/* This is where the transition effect comes into play */
.dropdown-content {
  transition: opacity 1s ease-in-out;
}

/* When the dropdown is open, display its content */
.dropdown-container:hover .dropdown-content {
  display: block;
  opacity: 1;
}

#search-general {
  color: #c2c2c2 !important;
}

.top {
  animation: animateTop .5s ease infinite;
}

.bottom {
  animation: animateBottom .5s ease infinite;
}

.right {
  animation: animateRight .5s ease infinite;
}

.left {
  animation: animateLeft .5s ease infinite;
}

@keyframes animateTop {
  25% {
    width: 100%;
    opacity: 1;
  }

  30%,
  100% {
    opacity: 0;
  }
}

@keyframes animateBottom {

  0%,
  50% {
    opacity: 0;
    width: 0;
  }

  75% {
    opacity: 1;
    width: 100%;
  }

  76%,
  100% {
    opacity: 0;
  }
}

@keyframes animateRight {

  0%,
  25% {
    opacity: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    height: 100%;
  }

  55%,
  100% {
    height: 100%;
    opacity: 0;
  }
}

@keyframes animateLeft {

  0%,
  75% {
    opacity: 0;
    bottom: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown.open .dropdown-menu {
  opacity: 1;
  display: grid;
  transform: scale(100%);
  transition: opacity 0.3s, transform 0.3s;
}

.dropdown.close .dropdown-menu {
  opacity: 0;
  display: none;
  transform: scale(100%);
  transition: opacity 0.3s, transform 0.3s;
}

.dropdown-toggle {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  transform: scale(80%);
  transition: opacity 0.3s, transform 0.3s;
}

@media (max-width: 429px) {
  .mini-header {
    font-size: 1rem;
  }

  .mini-header {
    position: relative;
    color: #569f90;
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-lg {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .item-container {
    background-color: var(--bg-body-primary);
    width: calc(100% - 20px);
    height: auto;
    border-bottom: 6px solid var(--bg-body-primary);
    border-top: 6px solid var(--bg-body-primary);
    border-radius: 6px;
    animation-name: mostrar-30;
    animation-duration: 1s;
    position: relative;
  }
}

#image-bg{
  width:100%;
  height:100vh;
  background-image: url('https://w0.peakpx.com/wallpaper/1017/1024/HD-wallpaper-happy-kids-drawing-children-joy-happy-kids-play.jpg');
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center center;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  @apply bg-brand-600 dark:bg-brand-950 p-1 rounded-lg cursor-pointer;
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.animate-spin-reverse {
  animation: spin-reverse .4s linear infinite;
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.animate-spin-reverse-2 {
  animation: spin-reverse .2s linear infinite;
}

@keyframes spin-normal {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-spin-normal {
  animation: spin-normal .3s linear infinite;
}

.MuiCircularProgress-root.MuiCircularProgress-determinate.MuiCircularProgress-colorSuccess{
  @apply text-green-500 dark:text-green-400 !important;
}
.MuiCircularProgress-root.MuiCircularProgress-determinate.MuiCircularProgress-colorError{
  @apply text-red-500 dark:text-red-400 !important;
}
.MuiCircularProgress-root.MuiCircularProgress-determinate.MuiCircularProgress-colorWarning{
  @apply text-orange-500 dark:text-orange-400 !important;
}

.price-tag {
  background-color: #dc2626; /* bg-red-600 */
  color: white; /* text-white */
  padding: 6px 23px;
  clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%);
  font-size: 1rem; /* text-2xl */
  text-align: center;
  transform: translateX(-50%);
}

.price-tag2 {
  background-color: #ff0000;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 0% 50%);
}

.price-categories {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 60%, 0 100%);
}

.loader-init {
  --size: 300px;
  --duration: 2s;
  --logo-color: grey;
  --background: linear-gradient(
    0deg,
    rgba(50, 50, 50, 0.2) 0%,
    rgba(100, 100, 100, 0.2) 100%
  );
  height: var(--size);
  aspect-ratio: 1;
  position: relative;
}

.loader-init .box {
  position: absolute;
  background: rgba(100, 100, 100, 0.15);
  background: var(--background);
  border-radius: 50%;
  border-top: 1px solid rgba(100, 100, 100, 1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
  backdrop-filter: blur(5px);
  animation: ripple var(--duration) infinite ease-in-out;
}

.loader-init .box:nth-child(1) {
  inset: 40%;
  z-index: 99;
}

.loader-init .box:nth-child(2) {
  inset: 30%;
  z-index: 98;
  border-color: rgba(100, 100, 100, 0.8);
  animation-delay: 0.2s;
}

.loader-init .box:nth-child(3) {
  inset: 20%;
  z-index: 97;
  border-color: rgba(100, 100, 100, 0.6);
  animation-delay: 0.4s;
}

.loader-init .box:nth-child(4) {
  inset: 10%;
  z-index: 96;
  border-color: rgba(100, 100, 100, 0.4);
  animation-delay: 0.6s;
}

.loader-init .box:nth-child(5) {
  inset: 0%;
  z-index: 95;
  border-color: rgba(100, 100, 100, 0.2);
  animation-delay: 0.8s;
}

.loader-init .logo {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
  padding: 30%;
}

.loader-init .logo svg {
  fill: var(--logo-color);
  width: 100%;
  animation: color-change var(--duration) infinite ease-in-out;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
  }
  50% {
    transform: scale(1.3);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 30px 20px -0px;
  }
  100% {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 10px -0px;
  }
}

@keyframes color-change {
  0% {
    fill: var(--logo-color);
  }
  50% {
    fill: white;
  }
  100% {
    fill: var(--logo-color);
  }
}

.card-social svg {
  height: 35px;
}

.card-social {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  height: 50px;
  width: 200px;
}

.card-social::before, .card-social::after {
  position: absolute;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  transition: 0.25s linear;
  z-index: 1;
}

.card-social::before {
  content: "";
  left: 0;
  justify-content: flex-end;
  @apply bg-darkPrimary;
}

.card-social::after {
  content: "";
  right: 0;
  justify-content: flex-start;
  @apply bg-lightPrimary;
}

.card-social:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-social:hover span {
  opacity: 0;
  z-index: -3;
}

.card-social:hover::before {
  opacity: 0.5;
  transform: translateY(-100%);
}

.card-social:hover::after {
  opacity: 0.5;
  transform: translateY(100%);
}

.card-social span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  font-family: 'Fira Mono', monospace;
  font-size: 24px;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
}

.card-social .social-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  color: whitesmoke;
  font-size: 24px;
  text-decoration: none;
  transition: 0.25s;
}

.card-social .social-link svg {
  text-shadow: 1px 1px rgba(31, 74, 121, 0.7);
  transform: scale(1);
}

.card-social .social-link:hover {
  background-color: rgba(249, 244, 255, 0.774);
  animation: bounce_613 0.4s linear;
}

@keyframes bounce_613 {
  40% {
    transform: scale(1.4);
  }

  60% {
    transform: scale(0.8);
  }

  80% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.sparkle {
  position: absolute;
  bottom: 0;
  width: 3px;
  height: 3px;
  @apply bg-orange-500 dark:bg-sky-200;
  /*background-color: rgba(181, 255, 255, 0.76); /* Color de chispas de fuego */
  border-radius: 50%;
  animation: float 20s linear infinite, flicker 1.5s ease-in-out infinite;
  opacity: 0.4;
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(calc(-100vh + 200px)) translateX(calc(100vw - 50vw - 50px));
  }
}

@keyframes flicker {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
}
